<template>
  <vue-plotly :data="mapData" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'
import geojson_2017 from '../assets/gemeente_2017.geojson'
import geojson_2018 from '../assets/gemeente_2018.geojson'
import geojson_2019 from '../assets/gemeente_2019.geojson'
import geojson_2020 from '../assets/gemeente_2020.geojson'
// Site to get geojson: https://cartomap.github.io/nl/wgs84/gemeente_2022.geojson. Just change the year in the url.

export default {
  name: 'Choropleth',
  components: { VuePlotly },
  props: {
    locations: {
      type: Array,
      default : () =>  null
    },
    hoverinfo: {
      type: String,
      default: ''
    },
    z: {
      type: Array,
      default : () =>  null
    },
    text: {
      type: Array,
      default : () =>  null
    },
    hovertemplate:{
      type: String,
      default: ''
    },
    dtick : {
      type: Number,
      default: null
    },
    tick0 : {
      type: Number,
      default: null
    },
    tickmax : {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: () => 440
    },
    zmin: {
      type: Number,
      default: () => 0
    },
    zmax: {
      type: Number,
      default: () => 3000
    },
    colorscale: {
      type: Array,
      default : () =>  null
    },
    zoom: {
      type: Number,
      default: () => 6
    },
    selectedRegion: {
      type: String,
      default: null
    },
    colorbar: {
      ticksuffix: {
        type: String,
        default: ''
      },
    },
    year: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      layout: {
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 20,
          pad: 4},
        mapbox: {
          style: 'white-bg',
          center: {
            lon: 5.104480,
            lat: 52.092876
          },
          zoom: this.zoom
        },
        height: this.height
      },
      options: {
        displayModeBar: false,
        responsive:true,
        maintainAspectRatio: false,
      },
    }
  },
  computed: {
    mapData ()  {
      let geojson
      if (this.year==2017){
        geojson = geojson_2017
      }
      else if (this.year==2018){
        geojson = geojson_2018
      }
      else if (this.year==2019){
        geojson = geojson_2019
      }
      else {
        geojson = geojson_2020
      }

      return [
          {
            type: 'choroplethmapbox',
            marker: {line: {color: 'rgba(180,180,180,0.2)', width: 0.1}},
            colorscale: this.colorscale,
            locations: this.locations,
            hoverinfo: 'z+text',
            geojson: geojson,
            text: this.text,
            z: this.z,
            zmin: this.zmin,
            zmax: this.zmax,
            hovertemplate: this.hovertemplate,
            colorbar: {
            ticksuffix: this.colorbar.ticksuffix,
            dtick: this.dtick,
            tick0: this.tick0,
            tickmax: this.tickmax
          },
          }
        ]
    }
  }
}

</script>
